@font-face {
  font-family: 'WebFont';  
  font-weight: 200;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'WebFont';  
  font-weight: 200;
  font-style: italic;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-ExtraLightItalic.ttf) format('truetype');
}


@font-face {
  font-family: 'WebFont';  
  font-weight: 300;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'WebFont';  
  font-weight: 300;
  font-style: italic;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'WebFont';  
  font-weight: 500;  
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-Medium.ttf) format('truetype');
}


@font-face {
  font-family: 'WebFont';  
  font-weight: 500;  
  font-style: italic;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-MediumItalic.ttf) format('truetype');
}


@font-face {
  font-family: 'WebFont';  
  font-weight: 400;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'WebFont';
  font-weight: 600;  
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'WebFont';
  font-weight: 600;  
  font-style: italic;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'WebFont';
  font-weight: 700;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'WebFont';
  font-weight: 700;
  font-style: italic;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'WebFont';
  font-weight: 900;
  src: local('WebFont'), url(./styles/fonts/TitilliumWeb/Inter_18pt-ExtraBold.ttf) format('truetype');
}

html{
  font-size: 12px;
  height:100%;
}

@media (min-width: 500px) {
  html{
    font-size: 14px;
  }
}


body{
  font-family: WebFont;
  min-height: 100vh;
  height:100%;
}

div.layout{
  display:flex;
  flex-direction:column;
  height:100%;
}

#root{
  height:100%;
}